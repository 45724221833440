<template>
  <div>
    <div class="pb-4 font-weight-bold">
      <span>{{
        $trans("available_hours_title", {
          date: $moment(startDate).format(helpers.dayMonthAndYearDate),
        })
      }}</span>
    </div>
    <div>
      <employee-booking-availability
        v-if="eventUpdateMode"
        v-model="localStartTime"
        :service="service"
        :employee="employee"
        :location="location"
        :start-date="startDate"
        :disabled="disabled"
        @update="handleEmployeeAvailabilityUpdate"
        @updateDate="handleEmployeeAvailabilityDateUpdate"
      />
      <employee-booking-availability
        v-else
        :service="service"
        :employee="employee"
        :location="location"
        :start-date="startDate"
        :disabled="disabled"
        @update="handleEmployeeAvailabilityUpdate"
        @updateDate="handleEmployeeAvailabilityDateUpdate"
      />
    </div>
  </div>
</template>

<script>
import EmployeeBookingAvailability from "@/components/Forms/EmployeeBookingAvailability.vue";
import helpers from "@/lib/calendesk-js-library/tools/helpers";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";

export default {
  components: { EmployeeBookingAvailability },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    eventUpdateMode: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    employee: {
      type: Object,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localStartTime: this.startTime,
    };
  },
  computed: {
    helpers() {
      return helpers;
    },
    computedStartTime: {
      get() {
        return this.localStartTime;
      },
      set(value) {
        this.localStartTime = value;
        this.$emit("update:startTime", value);
      },
    },
  },
  methods: {
    handleEmployeeAvailabilityUpdate(data) {
      this.scrollToTop();
      this.$emit("update", data);
    },
    handleEmployeeAvailabilityDateUpdate(data) {
      this.scrollToTop();
      this.$emit("updateDate", data);
    },
    scrollToTop() {
      if (!this.employee) {
        EventBus.$emit("MANAGE_BOOKING_EVENT_SCROLL_TO_TOP");
      }
    },
  },
};
</script>
