<template>
  <dialog-time-input
    v-if="$vuetify.breakpoint.width <= 960"
    v-model="selectedTime"
    :label="label"
    is-outlined
    :rules="rules"
    append-icon="$menu-down"
    :disabled="disabled"
    :min="min"
    :max="max"
    @input="valueEmitter"
  />
  <select-time-picker
    v-else
    v-model="selectedTime"
    :type="type"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :min="min"
    :max="max"
    @input="valueEmitter"
  />
</template>

<script>
import SelectTimePicker from "@/views/dashboard/pages/Calendar/components/SelectTimePicker";
import DialogTimeInput from "@/views/dashboard/pages/Calendar/components/DialogTimeInput";

export default {
  name: "CTimeInput",
  components: { SelectTimePicker, DialogTimeInput },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "false",
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    min: {
      type: String,
      default: "00:00",
    },
    max: {
      type: String,
      default: "23:59",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedTime: this.value,
    };
  },
  watch: {
    value() {
      this.selectedTime = this.value;
    },
  },
  methods: {
    valueEmitter($event) {
      this.$emit("input", $event);
      this.selectedTime = $event;
    },
  },
};
</script>
