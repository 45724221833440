<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row v-if="isLoading" no-gutters>
      <v-col class="pa-0 ma-0" cols="12">
        <v-container fluid>
          <v-row>
            <v-skeleton-loader
              v-for="number in 42"
              :key="number"
              class="mb-2 mr-2 float-left"
              height="20"
              type="button"
            />
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <template v-else>
      <template v-if="employee && !employeeInSlots">
        <div class="d-flex align-center justify-center text-body-2 text-center">
          <span v-if="schedulesV2Enabled">{{
            $trans("no_available_time_slots_v2")
          }}</span>
          <span v-else>
            {{ $trans("no_available_time_slots") }}
          </span>
        </div>
      </template>
      <template v-else>
        <template
          v-for="(item, itemIndex) in employee
            ? slots
            : slots.slice(0, numToShow)"
        >
          <v-row
            v-if="
              item.employee && (!employee || employee.id === item.employee.id)
            "
            :key="item.employee.id"
            no-gutters
          >
            <v-col class="pa-0 ma-0" cols="12">
              <avatar-user-names
                :employee="item.employee"
                :user="item.employee.user"
                medium
              />
            </v-col>
            <v-col class="pa-0 ma-0" cols="12">
              <v-container fluid>
                <template v-for="(timeSlots, timeSlotsIndex) in item.slots">
                  <v-row
                    v-if="timeSlotsIndex === selectedDate"
                    :key="item.employee.id + timeSlotsIndex"
                    justify="start"
                  >
                    <v-col class="px-0 mx-0">
                      <template v-if="timeSlots && timeSlots.length > 0">
                        <div
                          v-for="(slot, slotIndex) in timeSlots"
                          :key="item.employee.id + timeSlotsIndex + slotIndex"
                          class="float-left mr-2 mb-2"
                          :class="{
                            'mr-6 mb-4': isGroupService,
                          }"
                        >
                          <v-badge
                            v-if="isGroupService"
                            bordered
                            color="green"
                            overlap
                          >
                            <template #badge>
                              {{ selectedService.max_people - slot.used }}
                            </template>
                            <v-btn
                              :disabled="isLoading || disabled"
                              color="secondary"
                              class="cd-outline-no-color"
                              :outlined="
                                !isTimeSlotSelected(slot.time, item.employee)
                              "
                              small
                              @click="selectTimeSlot(slot.time, item.employee)"
                            >
                              <v-icon left>$clock</v-icon>
                              {{ slot.time }}
                            </v-btn>
                          </v-badge>
                          <v-btn
                            v-else
                            :disabled="isLoading || disabled"
                            :outlined="
                              !isTimeSlotSelected(slot.time, item.employee)
                            "
                            color="secondary"
                            class="cd-outline-no-color"
                            small
                            @click="selectTimeSlot(slot.time, item.employee)"
                          >
                            <v-icon left>$clock</v-icon>
                            {{ slot.time }}
                          </v-btn>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="d-flex align-center justify-center text-body-2 text-center"
                        >
                          <span v-if="schedulesV2Enabled">{{
                            $trans("no_available_time_slots_v2")
                          }}</span>
                          <span v-else>
                            {{ $trans("no_available_time_slots") }}
                          </span>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-container>
            </v-col>
            <v-col class="pa-0 ma-0" cols="12">
              <template v-for="date in uniqueDates.slice(1)">
                <div
                  v-if="slotsLeftForTheDay(item.employee.id, date) > 0"
                  :key="'uniqueDates_' + date"
                  class="float-left mr-4 mb-4"
                >
                  <v-badge bordered color="green" overlap>
                    <template #badge>
                      {{ slotsLeftForTheDay(item.employee.id, date) }}
                    </template>
                    <v-btn
                      :disabled="isLoading || disabled"
                      color="secondary"
                      outlined
                      @click="changeSelectedDate(date, item.employee)"
                    >
                      <v-icon left>$calendar</v-icon>
                      {{ date | moment($helpers.dayMonthAndYearDate) }}
                    </v-btn>
                  </v-badge>
                </div>
              </template>
            </v-col>
            <v-col
              v-if="itemIndex < slots.slice(0, numToShow).length - 1"
              cols="12"
            >
              <v-divider class="mb-4 mt-2" />
            </v-col>
          </v-row>
        </template>
        <v-row v-if="!employee && slots.length > numToShow">
          <v-col class="text-center" cols="12">
            <v-btn
              color="primary"
              :disabled="isLoading || disabled"
              @click="loadMore"
            >
              {{
                $trans("show_more_employees", {
                  number: slots.length - numToShow,
                })
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import helpers, {
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import AvatarUserNames from "@/components/AvatarUserNames.vue";

export default {
  name: "EmployeeBookingAvailability",
  components: { AvatarUserNames },
  model: { prop: "value", event: "change" },
  props: {
    value: { type: String, default: null },
    filter: { type: String, default: null },
    service: { type: [Object, null], default: null },
    employee: { type: [Object, null], default: null },
    location: { type: [Object, null], default: null },
    startDate: { type: String, default: null },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedHour: this.value,
      isLoading: false,
      slots: [],
      rawSlots: null,
      selectedDate: this.$moment(this.startDate).isAfter(this.$moment())
        ? this.startDate
        : this.$moment().format(helpers.dateFormat),
      selectedService: this.service,
      selectedLocation: this.location,
      selectedEmployee: this.employee,
      numToShow: this.$vuetify.breakpoint.mdAndUp ? 5 : 1,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/getAllEmployees",
      categoryWithServices: "category/categoryWithServices",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    schedulesV2Enabled() {
      return !!(
        this.adminConfiguration && this.adminConfiguration.schedules_v2_enabled
      );
    },
    getDuration() {
      return this.selectedService && this.selectedService.selectedType
        ? this.selectedService.selectedType.duration
        : this.selectedService.duration;
    },
    isGroupService() {
      return this.selectedService && this.selectedService.max_people > 1;
    },
    uniqueDates() {
      const dateSet = new Set();
      if (this.slots) {
        this.slots.forEach((slot) => {
          Object.keys(slot.slots).forEach((date) => {
            dateSet.add(date);
          });
        });
      }
      return Array.from(dateSet);
    },
    employeeInSlots() {
      if (this.employee && this.slots) {
        return !!this.slots.find(
          (item) => item.employee && item.employee.id === this.employee.id,
        );
      }
      return false;
    },
  },
  watch: {
    value(val) {
      if (this.selectedHour !== val) {
        this.selectedHour = val;
        this.reloadTimeSlots();
      }
    },
    startDate(val) {
      if (this.selectedDate !== val) {
        this.selectedDate = val;
        this.reloadTimeSlots();
      }
    },
    service(val) {
      if (
        !this.selectedService ||
        this.selectedService.id !== val.id ||
        (this.selectedService.selectedType &&
          val.selectedType &&
          this.selectedService.selectedType.id !== val.selectedType.id)
      ) {
        this.selectedService = val;
        this.reloadTimeSlots();
      }
    },
    location(val) {
      if (
        !this.selectedLocation ||
        !val ||
        (this.selectedLocation && val && this.selectedLocation.id !== val.id)
      ) {
        this.selectedLocation = val;
        this.reloadTimeSlots();
      }
    },
  },
  created() {
    this.reloadTimeSlots();
  },
  methods: {
    ...mapActions({
      fetchAvailableBookingSlots: "booking/fetchAvailableBookingSlots",
    }),
    getEndTime(startTime) {
      return this.$moment(`${this.selectedDate} ${startTime}`)
        .add(this.getDuration, "minutes")
        .format(this.$helpers.timeFormat);
    },
    changeSelectedDate(date, employee) {
      this.$emit("updateDate", {
        startDate: date,
        endDate: date,
        employee,
      });
    },
    reloadTimeSlots() {
      if (this.selectedService && this.selectedDate) {
        this.isLoading = true;

        const requestData = {
          used_slots: 1,
          number_of_days: 62,
          service_id: this.selectedService.id,
          start_date: this.selectedDate,
          service_type_id: this.selectedService.selectedType
            ? this.selectedService.selectedType.id
            : null,
          customer_time_zone: userTimeZone(),
        };

        if (this.schedulesV2Enabled && this.selectedLocation) {
          requestData.location_id = this.selectedLocation.location_id;
        }

        this.fetchAvailableBookingSlots(requestData)
          .then((response) => {
            this.rawSlots = response;
            this.slots = this.parseSlotsByAvailableHours(response);
            this.$emit("loaded");
          })
          .catch(() => {
            this.slots = [];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    // TODO: in case we would like to sort by employee ID, maybe a switch?
    // parseSlotsById(slots) {
    //   const result = [];
    //   for (const [key, value] of Object.entries(slots)) {
    //     const employee = this.getEmployeeById(key);
    //     if (employee) {
    //       result.push({ employee: this.getEmployeeById(key), slots: value });
    //     }
    //   }
    //   return result;
    // },
    parseSlotsByAvailableHours(slots) {
      const result = [];
      for (const [key, value] of Object.entries(slots)) {
        const employee = this.getEmployeeById(key);
        if (employee) {
          // Count available slots for the selected date
          const availableSlots =
            value[this.selectedDate]?.filter((slot) => slot.used === 0)
              .length || 0;

          // Count total available slots for all the dates
          const totalAvailableSlots = Object.values(value)
            .flat()
            .filter((slot) => slot.used === 0).length;

          result.push({
            employee: this.getEmployeeById(key),
            slots: value,
            availableSlots,
            totalAvailableSlots,
          });
        }
      }

      // Sort the result array based on the number of available slots and total available slots
      result.sort((a, b) => {
        if (b.availableSlots - a.availableSlots !== 0) {
          return b.availableSlots - a.availableSlots;
        }
        return b.totalAvailableSlots - a.totalAvailableSlots;
      });

      return result;
    },
    getEmployeeById(employeeId) {
      if (this.employees) {
        return this.employees.find(
          (employee) => employee.id === parseInt(employeeId),
        );
      }
      return null;
    },
    selectTimeSlot(timeSlot, employee) {
      this.selectedHour = timeSlot;
      this.$emit("update", {
        startDate: this.selectedDate,
        startTime: timeSlot,
        endDate: this.selectedDate,
        endTime: this.getEndTime(timeSlot),
        employee,
      });
    },
    isTimeSlotSelected(timeSlot, employee) {
      return (
        this.selectedHour === timeSlot &&
        this.employee &&
        employee &&
        this.employee.id === employee.id
      );
    },
    loadMore() {
      this.numToShow += this.$vuetify.breakpoint.mdAndUp ? 5 : 3;
    },
    slotsLeftForTheDay(employeeId, date) {
      if (
        this.rawSlots &&
        this.rawSlots[employeeId] &&
        this.rawSlots[employeeId][date]
      ) {
        return this.rawSlots[employeeId][date].length;
      }

      return 0;
    },
  },
};
</script>
