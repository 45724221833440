import {
  maxNumber,
  minNumber,
  required,
  requiredArray,
  maxChars,
} from "@/lib/calendesk-js-library/forms/validators";
import { mapActions, mapGetters } from "vuex";
import roleActions from "@/calendesk/mixins/roleActions";
import {
  calculateDuration,
  getConvertedEventDates,
  parseRruleString,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [roleActions],
  data() {
    return {
      disabledForm: false,
      form: {
        data: null,
        id: null,
        allDay: false,
        wantsSetEndTime: false,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        employee: null,
        user: null,
        users: null,
        service: null,
        tags: null,
        products: null,
        description: null,
        status: null,
        type: null,
        recurrenceParams: null,
        initialRecurrenceParams: null,
        initialType: null,
        skipNotifications: false,
        serviceLocationId: null,
        serviceLocation: null,
        groupId: null, // recurrence group id
        multiSlotGroupId: null, // group id, the same date&time
        paid: false,
        googleMeetUrl: null,
        zoomJoinUrl: null,
        teamsUrl: null,
        skypeUrl: null,
        customerWhatsAppUrl: null,
        employeeWhatsAppUrl: null,
        createdBy: null,
        updatedBy: null,
        createdAt: null,
        updatedAt: null,
      },
      rules: {
        required,
        maxNumber,
        minNumber,
        requiredArray,
        maxChars,
      },
      recurringBookingEndsSelectedTypes: {
        OCCURRENCES: 1,
        DATE: 2,
      },
      recurringBookingEndsSelectedType: 1,
      recurringBooking: false,
      recurringBookingRepeatEvery: 1,
      recurringBookingEndsAfter: 13,
      recurringBookingEndsAt: null,
      minDate: this.$moment().format(this.$helpers.dateFormat),
      maxDate: this.$moment().add(10, "years").format(this.$helpers.dateFormat),
      recurringBookingSelectedFrequency: "WEEKLY",
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/getAllEmployees",
      adminConfiguration: "setup/getAdminConfiguration",
      manageEventButtonLoadingState: "booking/getManageEventButtonLoadingState",
    }),
    minEndTime() {
      if (
        this.$moment(this.form.endDate).isAfter(
          this.$moment(this.form.startDate),
        )
      ) {
        return undefined;
      }

      return this.form.startTime;
    },
    datesInThePast() {
      if (this.form.startDate && this.form.startTime) {
        return this.$moment(
          `${this.form.startDate} ${this.form.startTime}`,
          this.$helpers.dateTimeFormat,
        ).isBefore(this.$moment());
      }

      return false;
    },
    recurringBookingRepeatEveryMaxNumber() {
      return 100;
    },
    recurringBookingFrequencies() {
      const freq = [
        {
          text: this.$trans("day"),
          value: "DAILY",
        },
        {
          text: this.$trans("week"),
          value: "WEEKLY",
        },
        {
          text: this.$trans("month"),
          value: "MONTHLY",
        },
      ];

      if (!this.isZoomSelected) {
        freq.push({
          text: this.$trans("year"),
          value: "YEARLY",
        });
      }

      return freq;
    },
  },
  watch: {
    manageEventButtonLoadingState(value) {
      this.disabledForm = value;
    },
  },
  methods: {
    ...mapActions({
      setManageEventTitle: "booking/setManageEventTitle",
      setManageEventButtonTitle: "booking/setManageEventButtonTitle",
      setManageEventButtonState: "booking/setManageEventButtonState",
      fetchStats: "dashboard/fetchStats",
    }),
    reloadForm() {
      this.form.data = this.event.data;
      this.form.id = this.event.id;

      this.form.wantsSetEndTime = !!this.event.allDay;
      this.form.allDay = !!this.event.allDay;

      if (!this.hasPermission(this.employeePermissions.SHOW_EMPLOYEES)) {
        this.form.employee = this.employees[0];
      } else {
        this.form.employee = this.event.employee;
      }

      this.form.description = this.event.description;
      this.form.type = this.event.type;
      this.form.initialType = this.event.type;
      this.form.groupId = this.event.groupId;
      this.form.recurrenceParams = this.event.recurrenceParams;
      this.form.initialRecurrenceParams = this.event.recurrenceParams;

      if (this.form.recurrenceParams) {
        this.recurringBooking = true;
        const rruleProperties = parseRruleString(this.form.recurrenceParams);
        this.recurringBookingSelectedFrequency = rruleProperties.FREQ;
        this.recurringBookingRepeatEvery = parseInt(
          rruleProperties.INTERVAL ?? 1,
          10,
        );

        if (rruleProperties.COUNT) {
          this.recurringBookingEndsSelectedType =
            this.recurringBookingEndsSelectedTypes.OCCURRENCES;
          this.recurringBookingEndsAfter = parseInt(rruleProperties.COUNT, 10);
        } else if (rruleProperties.UNTIL) {
          this.recurringBookingEndsSelectedType =
            this.recurringBookingEndsSelectedTypes.DATE;
          this.recurringBookingEndsAt = this.$moment(
            rruleProperties.UNTIL,
          ).format("YYYY-MM-DD");
        }
      }

      this.form.createdBy = this.event.createdBy;
      this.form.updatedBy = this.event.updatedBy;
      this.form.createdAt = this.event.createdAt;
      this.form.updatedAt = this.event.updatedAt;

      const cleanStartTime = this.$moment().format("HH:00");
      const cleanEndTime = this.$moment()
        .add(1, "day")
        .add(60, "minutes")
        .format("HH:00");

      const convertedEventDates = getConvertedEventDates(
        this.event.startsAt,
        this.event.endsAt,
        !(this.event.startTime && this.event.endTime),
      );

      if (this.event.startDate && this.event.endDate && !this.event.duplicate) {
        this.form.startDate = convertedEventDates.formattedStartDate;
        this.form.endDate = convertedEventDates.formattedEndDate;
      } else {
        if (this.event.duplicate) {
          // Get the day of the week from this.event.startDate
          let eventDayOfWeek = this.$moment(
            convertedEventDates.formattedStartDate,
          ).day();

          // Get the next occurrence of the same day of the week
          let nextDayOfWeek = this.$moment()
            .day(eventDayOfWeek)
            .hour(0)
            .minute(0)
            .second(0);

          // Check if the next occurrence is in the past. If yes, get the next week's day
          if (nextDayOfWeek.isBefore(this.$moment())) {
            nextDayOfWeek.add(1, "week");
          }

          // Format the date
          nextDayOfWeek = nextDayOfWeek.format(this.$helpers.dateFormat);

          // Set the startDate and endDate
          this.form.startDate = nextDayOfWeek;
          this.form.endDate = nextDayOfWeek;
        } else {
          this.form.startDate = this.$moment()
            .add(1, "day")
            .format(this.$helpers.dateFormat);
          this.form.endDate = this.form.startDate;
        }

        this.form.startTime = cleanStartTime;
        this.form.endTime = cleanEndTime;
      }

      if (this.event.startTime && this.event.endTime) {
        this.form.startTime = convertedEventDates.formattedStartTime;
        this.form.endTime = convertedEventDates.formattedEndTime;

        // This is a situation where we have varying durations,
        // implying that the user has manually selected the time instead of using a service duration.
        if (
          (this.event.type === this.$helpers.eventTypes.booking ||
            this.event.type === this.$helpers.eventTypes.groupBooking) &&
          this.event.service
        ) {
          const serviceDuration = this.event.service.selectedType
            ? this.event.service.selectedType.duration
            : this.event.service.duration;

          const realDuration = calculateDuration(
            this.event.startDate,
            this.event.endDate,
            this.event.startTime,
            this.event.endTime,
          );

          if (serviceDuration !== realDuration) {
            this.form.wantsSetEndTime = true;
          }
        } else if (!this.event.service) {
          this.form.wantsSetEndTime = true;
        }
      } else {
        this.form.startTime = this.$moment().format("HH:00");
        this.form.endTime = this.$moment()
          .add(1, "day")
          .add(60, "minutes")
          .format("HH:00");
      }
    },
  },
};
